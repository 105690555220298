import { Injectable } from "@angular/core";

@Injectable()
export class SubscriptionApplicationStatesUtil {
  private states = [
    "Nuevo",
    "Pendiente de Validación",
    "Validado",
    "Pendiente de Documentos",
    "Pendiente Validación de Documentos",
    "Sin Disponibilidad Reserva",
    "Pendiente Suscripción de Pago",
    "Pendiente Firma de Contrato",
    "Esperando al Cliente",
    "Completado",
    "Aceptado",
    "Preaprobado",
    "Rechazado",
    "Perdido",
    "Cancelado por Cliente",
    "Vencido",
  ];

  /**
   * Compara dos estados del flujo según su posición en éste.
   * @param stateA estado base
   * @param stateB estado de comparación
   * @return 0 si es el mismo estado, 0 < si es un estado adelante y < 0 si es un estado previo.
   * Ej1: compare('Nuevo', 'Nuevo') === 0
   * Ej2: compare('Pendiente de Documentos', 'Pendiente de Validación') === -2
   * Ej3: compare('Pendiente Suscripción de Pago', 'Esperando al Cliente') === 2
   */
  public compare(stateA: string, stateB: string): number {
    return this.states.indexOf(stateB) - this.states.indexOf(stateA);
  }
}
